<template>
  <v-container >
    <v-layout wrap justify-center>
      <v-card class="ma-0 mt-0" width="350px">
        <v-form id=sensor >
          <div>센서노드 정보 {{ sensor_ID }} </div>
            
          <v-layout style="height: 50px; width: 380px;">
            <v-text-field dense outlined class="shrink mr-1" style="height: 45px; width: 200px; margin: 5px;"
              id="sensor_mac" 
              ref="sensor_mac" 
              label="센서노드 맥✽" 
              placeholder="mac주소를 입력해주세요."
              v-model="sensor_mac"
              @keyup="sensorMacMask(sensor_mac)"
              maxlength="20"
              clearable
            />
            <!-- 센서정보 팝업 -->
            <v-dialog
              v-model="dialog"
              max-width="700"
              max-height="100"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="rounded elevation-5 normal-btn ma-2" style="height: 35px; width: 80px;" 
                  v-bind="attrs"
                  :v-on="sensor_mac && sensor_mac.length > 16  ? 'on' : 'off' "
                  :loading="loadingCheckData"
                  id="checkData" 
                  ref="checkData" 
                  @click="sensor_mac && sensor_mac.length > 16 ? checkData() : $store.commit('resMessage','맥주소를 확인하세요');"
                >
                  수신확인
                </v-btn>
              </template>
              <v-card height="auto" width="auto">
                <v-card-text>
                  <v-row class="text-center">
                    <v-col class="mb-3">
                      <h2 class="font-weight-bold ma-2 black--text">
                        측정 시간 : {{ updated_at }}
                      </h2>
                    </v-col>
                  </v-row>

                  <v-row align="center" justify="center" no-gutters>
                      <GChart
                        :settings="{ packages: ['corechart', 'gauge'] }"
                        type="Gauge"
                        :data="chartData.temp"
                        :options="chartOptions.temp"
                      />

                      <GChart
                        :settings="{ packages: ['corechart', 'gauge'] }"
                        type="Gauge"
                        :data="chartData.hum"
                        :options="chartOptions.hum"
                      />

                      <GChart
                        :settings="{ packages: ['corechart', 'gauge'] }"
                        type="Gauge"
                        :data="chartData.co2"
                        :options="chartOptions.co2"
                      />

                      <GChart
                        :settings="{ packages: ['corechart', 'gauge'] }"
                        type="Gauge"
                        :data="chartData.nh3"
                        :options="chartOptions.nh3"
                      />
                  </v-row>
                </v-card-text>
                <v-card-actions align-content-center>
                  <v-spacer></v-spacer>
                  <v-btn rounded class="new-btn" style="height: 30px; width: 20px;  margin-bottom: 15px" 
                    id="confirm" 
                    ref="confirm" 
                    @click="dialog = false">
                    확인
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- 센서정보 팝업 끝 -->
          </v-layout>
            <v-select dense outlined class="ma-1 mt-8" style="height: 45px; width: auto;" v-show=false
              id="company" 
              ref="company" 
              label="경영체✽" 
              v-model="company"
              :items="companyItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다.."
              item-text="name"
              return-object
              @change="changeCompany()"
              @focus="comboCompanies()"
              clearable
            />
            <v-select dense outlined  class="ma-1" style="height: 45px; width: auto;" 
              id="farm" 
              ref="farm" 
              label="농장/사업장✽" 
              v-model="farm"
              :items="farmItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @change="changeFarm()"
              @focus="comboFarms()"
              clearable
            />
            <v-select dense outlined  class="ma-1" style="height: 45px; width: auto;"
              id="building" 
              ref="building" 
              label="축사/건물/구역✽" 
              v-model="building"
              :items="buildingItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @change="changeBuilding()"
              @focus="comboBuildings()"
              clearable
            />
            <v-select dense outlined  class="ma-1" style="height: 45px; width: auto;"
              id="room" 
              ref="room" 
              label="돈방/위치✽" 
              v-model="room"
              :items="roomItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @change="changeRoom()"
              @focus="comboRooms()"
              clearable
            />
            <v-layout>
          <v-menu
            v-model="menuSensorSetdate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="shrink mr-1 ml-1" style="width: 180px" 
                id="setdate" 
                ref="setdate" 
                v-model="setdate"
                label="설치일✽"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="setdate"
              @input="menuSensorSetdate = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menuSensorDeldate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="shrink mr-1" style="width: 180px"
                id="deldate" 
                ref="deldate" 
                v-model="deldate"
                label="중지일"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="deldate"
              @input="menuSensorDeldate = false"
            ></v-date-picker>
          </v-menu>
          </v-layout>
          <v-textarea outlined dense rows="2" name="input-7-4" style="height: auto; margin: -15px 3px 0px 5px"
            label="비고"
            id="historySensor" 
            ref="historySensor" 
            v-model="historySensor"
            value=""
            persistent-hint :hint="hintSensor"
          ></v-textarea>
      
          <v-card-actions class="d-block" >
            <v-row justify= "center" >
              <v-spacer ></v-spacer>
              <v-btn rounded class="normal-btn mr-2" style="height: 30px; width: 20px;  margin-bottom: 15px"
                v-if="this.$getters.userInfo.grade < 'UT02'"
                :loading="loadingSave" 
                id="save" 
                ref="save" 
                @click="saveSensor()">
                저장
              </v-btn>
              <v-spacer ></v-spacer>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import Common from "@/utils/custom/common.js";
import URLS from "@/api/urls.js";
import { GChart } from "vue-google-charts";

const gaugeWidth = 150;
const gaugeHeight = 150;

export default {
  name: 'QRSensornode',
  components: {
    GChart,
  },

  data: () => ({
    chartData: {
      temp: [
        ["Lavel", "Value"],
        ["온도", 0],
      ],
      hum: [
        ["Lavel", "Value"],
        ["습도", 0],
      ],
      co2: [
        ["Lavel", "Value"],
        ["CO₂", 0],
      ],
      nh3: [
        ["Lavel", "Value"],
        ["NH₃", 0],
      ],
    },
    chartOptions: {
      temp: {
        chart: {
          // title: 'Company Performance',
          // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
        },
        width: gaugeWidth,
        height: gaugeHeight,
        greenFrom: 20,
        greenTo: 30,
        redFrom: -20,
        redTo: 10,
        yellowFrom: 30,
        yellowTo: 50,
        minorTicks: 5,
        min: -20,
        max: 50,
        majorTicks: ["-20","-10", "0", "10", "20", "30", "40", "50"],
      },
      hum: {
        chart: {
          // title: 'Company Performance',
          // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
        },
        width: gaugeWidth,
        height: gaugeHeight,
        redFrom: 90,
        redTo: 100,
        yellowFrom: 0,
        yellowTo: 30,
        minorTicks: 5,
        majorTicks: ["0", "20", "40", "60", "80", "100"],
      },
      co2: {
        chart: {
          // title: 'Company Performance',
          // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
        },
        width: gaugeWidth,
        height: gaugeHeight,
        redFrom: 6000,
        redTo: 8000,
        yellowFrom: 4000,
        yellowTo: 6000,
        minorTicks: 5,
        min: 100,
        max: 8000,
        majorTicks: ["0", "2K", "4K", "6K", "8K"],
      },
      nh3: {
        chart: {
          // title: 'Company Performance',
          // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
        },
        width: gaugeWidth,
        height: gaugeHeight,
        redFrom: 90,
        redTo: 100,
        yellowFrom: 75,
        yellowTo: 90,
        minorTicks: 5,
        min: 0,
        max: 100,
        majorTicks: ["0", "20", "40", "60", "80", "100"],
      },
    },

    dialog: false,
    updated_at: "-",

    company : {},
    companyItems : [],
    farm : {},
    farmItems : [],
    building : {},
    buildingItems : [],
    room : {},
    roomItems : [],

    sensor_ID: "",
    sensor_mac: "",
    setdate: "",
    deldate: "",
    historySensor: "",

    msgFlag: true,
    selOption: "live",
    menuSensorSetdate: false,
    menuSensorDeldate: false,
    loadingSensorMac: false,
    loadingSave: false,
    loadingCheckData: false,
    receiveOK: false, 
    hintSensor: "",

  }),
  async created() {
    await Apis.pageCount({ //페이지뷰 카운터
      page_name: this.$route.name,
      url: this.$route.path,
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

    this.comboCompanies();
    this.comboFarms();
    this.comboBuildings();
    this.comboRooms();

    let url = new URL(window.location.href);      // URL 파라미터 유무 검사
    let urlParams = url.searchParams;             // URLSearchParams 객체
    let mac = urlParams.get('mac');
    this.infoDetail(mac);
  },

  methods: {
    chartClear() {
      this.updated_at ="수신 없슴";
      this.chartData.temp = [["Lavel", "Value"], ["온도", 0]];
      this.chartData.hum = [["Lavel", "Value"], ["습도", 0]];
      this.chartData.co2 = [["Lavel", "Value"], ["CO₂", 0]];
      this.chartData.nh3 = [["Lavel", "Value"], ["NH₃", 0]];
    },
    checkData() {
      this.receiveOK = false; 
      this.chartClear();
      let mac = this.sensor_mac.replace(/:/gi, '');
      this.dialog= false;
      if ( mac.length < 12) {
        this.dialog= false;
        return;
      }  
      this.loadingCheckData= true; 
      this.$store.commit("resMessage","");
      this.$axios
        .get(URLS.redis.lastValue + '/' + mac, null)
        .then((res) => {
          if (res.data) {
            // console.log("Response Data : " + JSON.stringify(res.data));

            const LabelValue = ["Lavel", "Value"];

            this.chartData.temp = [LabelValue, ["온도", res.data.value.temp]];
            this.chartData.hum = [LabelValue, ["습도", res.data.value.hum]];
            this.chartData.co2 = [LabelValue, ["CO₂", res.data.value.co2]];
            this.chartData.nh3 = [LabelValue, ["NH₃", res.data.value.nh3]];

            this.updated_at = res.data.local_dt;
            this.$store.commit("resMessage","정상 처리되었습니다.");
            this.loadingCheckData= false; 
            this.dialog= true;
            this.receiveOK = true; 
          } else {
            this.$store.commit("resMessage","센서정보가 없습니다.");
            this.loadingCheckData= false; 
            this.dialog= false; 
            // alert(this.sensor.name + 'Sensor 정보 없음')
          }
        });

    },
    infoDetail(mac) {
      this.$store.commit("resMessage","");
      this.loadingMac = true;
      this.clearSensorData();
      this.sensor_mac = Common.getMacMask(mac);
      Apis.infoSensorByMac({
        mac: mac, 
        },(res) => {  
          if (res.result) {
            if (res.resultCode == "W201") {
              this.$store.commit("resMessage","미등록 센서노드 입니다");
              this.loadingMac = false;
              return;
            }
            // console.log(res.data);
            this.sensor_ID = res.data.id;
            // this.sensor_mac = Common.getMacMask(res.data.sensor_mac);
            this.company = {code: res.data.company_cd, name: res.data.company};
            this.farm = {code: res.data.farm_cd, name: res.data.farm};
            this.building = {code: res.data.building_cd, name: res.data.building};
            this.room = {code: res.data.room_cd, name: res.data.room + " " + res.data.farm };
            this.setdate = res.data.setdate;
            this.deldate = res.data.deldate;
            this.historySensor = res.data.history; 
            this.$store.commit("resMessage",res.message);
            this.hintSensor = res.data.regdate + "에 등록, " + res.data.chguser + "님이 " + res.data.chgdate + "에 최종 수정";
            this.receiveOK = false; 
            this.loadingSensor = false;
          } else {
            this.loadingSensor = false;
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("infoSensor API 호출 오류",err)
            this.loadingSensor = false;
            // alert(err);
        }
      ); 
    },
    saveSensor() { 
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkSensorData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        return;
      }
      this.loadingSave = true;
      if (this.sensor_ID) {
        Apis.updateSensor({
          "id" : this.sensor_ID,
          "sensor_mac": this.sensor_mac.replace(/:/gi, ''),
          "smart_mac": "",
          "sensor_id": 0,
          "company_cd": this.company.code,
          "farm_cd" : this.farm.code,
          "building_cd" :	this.building.code,
          "room_cd" :	this.room.code,
          "zone_no" :	1,
          "device_name" :	this.room.name.replace(this.farm.name,"").trim(),
          "setdate" :	this.setdate,
          "deldate" :	this.deldate || "",
          "history" : this.historySensor.trim(),
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingSave = false;
              this.clearSensorData();
            } else {
              this.loadingSave = false;
              console.log("updateSensor API 호출 오류",res.message)
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("updateSensor API 호출 오류",err)
              alert(err);
          }
        )
      } else {
        if (!this.receiveOK) {
          if (!confirm("데이터 수신이 확인 되지 않았습니다. \n 그래도 우선 등록 하시겠습니까?"))  return;
        } 
        Apis.insertSensor({
          "sensor_mac": this.sensor_mac.replace(/:/gi, ''),
          "smart_mac": "",
          "sensor_id": 0,
          "company_cd": this.company.code,
          "farm_cd" : this.farm.code,
          "building_cd" :	this.building.code,
          "room_cd" :	this.room.code,
          "zone_no" :	1,
          "device_name" :	this.room.name.replace(this.farm.name,"").trim(),
          "setdate" :	this.setdate,
          "deldate" :	"",
          "history" : this.historySensor.trim(),
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingSave = false;
              this.clearSensorData();
            } else {
              this.loadingSave = false;
              this.$store.commit("resMessage",res.message);
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("insertSensor API 호출 오류",err)
              alert(err);
            }
        )
      }
    },
    clearSensorData() {
      this.sensor_ID = "";
      this.sensor_mac = "";
      // this.smart_mac = "";
      // this.sensor_id = "";
      this.company = {};
      this.farm = {};
      this.building = {};
      this.room = {};
      this.setdate = "";
      this.deldate = "";
      this.historySensor = "";
      this.receiveOK = false; 
      this.hintSensor = "";

    }, 
    checkSensorData() {
      if (!this.sensor_mac) {
        this.$refs.sensor_mac.focus();
        return "센서 맥 주소를 입력하세요";
      }
      let mac = this.sensor_mac.replace(/:/gi, '')
      if (mac.length < 12) {
        this.$refs.sensor_mac.focus();
        return "센서 맥 주소가 유효하지 않습니다.";
      }
      if (!this.farm.code) {
        this.$refs.farm.focus();
        return "농장/사업장을 선택입력하세요";
      }
      if (!this.building.code) {
        this.$refs.building.focus();
        return "축사/건물/구역을 선택입력하세요";
      }
      if (!this.room.code) {
        this.$refs.room.focus();
        return "돈방/위치를 선택입력하세요";
      }
      if (!this.setdate) {
        this.$refs.setdate.focus();
        return "설치일(가동시작일)을 선택입력하세요";
      }
      return "";
    },
    comboCompanies() {
      Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};
      this.building = {};
    },
    comboFarms() {
      Apis.comboFarms({
        // company_cd: this.company && this.company.code || "",
        company_cd: "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
      }
        this.building = {};
        this.room = {};
    },
    comboBuildings() {
      Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.building && this.building.company_cd) {
        this.company = {code: this.building.company_cd, name: this.building.company_name};
      }
      if (this.building && this.building.farm_cd) {
        this.farm = {code: this.building.farm_cd, name: this.building.farm_name};
      }
        this.room = {};
    },
    comboRooms() {
      Apis.comboRooms({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        building_cd: this.building && this.building.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.roomItems = [];
            for (let i in res.data) {
            this.roomItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              building_cd: res.data[i].building_cd, 
              building_name: res.data[i].building_name, 
              code: res.data[i].room_cd,
              name: res.data[i].room_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboRooms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeRoom() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.room && this.room.company_cd) {
        this.company = {code: this.room.company_cd, name: this.room.company_name};
      }
      if (this.room && this.room.farm_cd) {
        this.farm = {code: this.room.farm_cd, name: this.room.farm_name};
      }
      if (this.room && this.room.building_cd) {
        this.building = {code: this.room.building_cd, name: this.room.building_name};
      }
    },
    sensorMacMask(val) {
      this.sensor_mac = Common.getMacMask(val)
    },

  }  // -----------methods 

}
// 01-06
</script>

<style lang="scss">

.v-data-table1 .v-data-table-header tr th {
  font-size: 14px !important;
  font-weight: bold !important;
  color:rgb(244, 244, 244) !important;
  background-color: rgb(77, 111, 86) !important;
}
.v-data-table2 .v-data-table-header tr th {
  font-size: 12px !important;
  font-weight: bold !important;
  color:rgb(244, 244, 244) !important;
  background-color: rgb(77, 111, 86) !important;
}
</style>
<style lang="scss" scoped>
.normal-btn{
  color:white !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-size:12pt  !important;
  font-weight: bold !important;
  background-color: #88191a !important;
}
</style>
